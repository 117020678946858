import { ReactElement } from 'react';
import { Datagrid, FunctionField, useGetRecordId } from 'react-admin';

import CurrencyField from '../../../../components/CurrencyField';
import { Country } from '../../../../types';
import {
  isCreditPaybackItem,
  isDebtConsolidationPaybackItem,
  isTransactionItem,
  PaybackItem,
  PaybackItemType
} from '../../../../types/paymentPlan';
import RemoveFeeButton from './RemoveFeeButton';

type Props = {
  items: PaybackItem[];
  country: Country;
};

const formatProductType = (item: PaybackItem): string => {
  let productPrefix = '';
  if (isCreditPaybackItem(item)) {
    productPrefix = 'VARDA';
  } else if (isDebtConsolidationPaybackItem(item)) {
    productPrefix = 'LÖSA';
  }

  let productItemType = item.type as string;
  if (productPrefix) {
    switch (item.type) {
      case PaybackItemType.PAYBACK_INTEREST:
        productItemType = `${productPrefix}_INTEREST`;
        break;
      case PaybackItemType.PAYBACK_PRINCIPAL:
        productItemType = `${productPrefix}_PRINCIPAL`;
        break;
    }
  }

  return productItemType;
};

export type PaybackItemDetails = { id: string; amount: number; type: string } & (
  | { isRemovable: boolean }
  | { amountPaid: number | undefined }
);

const PaymentPlanItemList = ({ items, country }: Props): ReactElement | null => {
  const agreementId = useGetRecordId();
  const paybackItemDetails: PaybackItemDetails[] = items
    .map(item => {
      const extraProperties = isTransactionItem(item)
        ? { isRemovable: item.isRemovable }
        : { amountPaid: item.amountPaid };

      return {
        id: item.id,
        amount: item.amount,
        type: formatProductType(item),
        ...extraProperties
      };
    })
    .sort((a, b) => (a.type > b.type ? -1 : 1));

  return (
    <FunctionField
      label="Details"
      render={() => (
        <Datagrid
          data={paybackItemDetails}
          bulkActionButtons={false}
          size="small"
          sx={{
            '& .RaDatagrid-table': { tableLayout: 'fixed', maxWidth: '650px' },
            '& .RaDatagrid-headerRow': { display: 'none' },
            '& .RaDatagrid-rowCell': { textAlign: 'right' },
            whiteSpace: 'nowrap'
          }}
        >
          <CurrencyField label="" source="amount" record={{ country }} />
          <CurrencyField label="" source="amountPaid" record={{ country }} />
          <FunctionField source="type" render={formatProductType} />
          <RemoveFeeButton agreementId={String(agreementId)} />
        </Datagrid>
      )}
    />
  );
};

export default PaymentPlanItemList;
